import { Spinner, SpinnerSize } from "@fluentui/react";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { dispatchRequest } from "../../../api/apiUtils";
import {
  finilizeFlowRequest,
  getEmployeeRequest,
  sendEmail,
  sendSMSRequest,
} from "../../../api/checkinApi";
import {
  getCompanyGuidFromId,
  getCompanyRequest,
  getConfigRequest,
} from "../../../api/companyApi";
import { getCompanyImage } from "../../../api/fileServiceApi";
import {
  printLeverandoerReceiptRequest,
  printReceiptRequest,
} from "../../../api/printApi";
import ClientConfig from "../../../config/clientConfig";
import Courier_Rovsing from "../../../images/rovsing_courier.jpg";
import { LocalizationDictionary } from "../../../localization/localizationDictionary";
import { IApplicationState } from "../../../store";
import { IConfigDataState } from "../../../store/ConfigData/types";
import { ICustomerDataState } from "../../../store/CustomerData/types";
import { resetStoreState } from "../../../store/Flow/actions";
import { IFlowState } from "../../../store/Flow/types";
import { ILayoutDataState } from "../../../store/LayoutData/types";
import { ILocalizationState } from "../../../store/Localization/types";
import StylesUtil from "../../../utils/StylesUtil";
import { ZebraPrintGuestCard } from "../../../utils/printUtils";
import HomeButton from "../../LayoutElements/HomeButton/HomeButton";
import { setCustomerData } from "../../../store/CustomerData/action";
import "./index.css";

interface ILogo {
  logo: any;
}

interface IState {
  loading: boolean;
  logo: string;
  company: any;
}

interface IDispatchProps {
  resetStoreState: typeof resetStoreState;
  setCustomerData: typeof setCustomerData;
}

type Props = IDispatchProps &
  IFlowState &
  ICustomerDataState &
  ILocalizationState &
  ILogo &
  ILayoutDataState &
  IConfigDataState;

class MessagePage extends React.Component<Props, IState> {
  public state: IState = {
    loading: true,
    logo: "",
    company: null,
  };

  private logo = "";
  private readonly strings = LocalizationDictionary.getStrings;

  private sendNotifications = async (
    companyId: number,
    flow: FlowType,
    appointmentId: number,
    employeeId: number
  ) => {
    let flowType: number;
    const company = await dispatchRequest(await getCompanyRequest(companyId));
    const { data, layoutData, language } = this.props;

    switch (flow) {
      case "CheckinAppointment": {
        flowType = 0;

        const emailInfo: SendEmailDTO = {
          flowType,
          companyId,
          appointmentId,
          localizationCode: language,
        };
        if (layoutData.code === "Heimdalls") {
          if (ClientConfig.flow.informAllEnabled) {
            this.sendEmailMessage(emailInfo);
          }
        }

        if (layoutData.code !== "Reisswolf") {
          if (layoutData.code === "FriskSnit") {
            this.sendEmailMessage(emailInfo);

            const smsInfo: SMSInfoDTO = {
              flowType,
              companyId,
              appointmentId,
              recipient: "",
              localizationCode: language,
            };

            this.sendSmsMessage(smsInfo);
            break;
          }

          var employee;
          employee = await dispatchRequest(
            await getEmployeeRequest(employeeId)
          );

          if (employee.emailNotification) {
            this.sendEmailMessage(emailInfo);
          }

          if (employee.smsNotification) {
            const smsInfo: SMSInfoDTO = {
              flowType,
              companyId,
              appointmentId,
              recipient: employee.phoneNumber,
              localizationCode: language,
            };

            this.sendSmsMessage(smsInfo);
          }

          if (layoutData.code === "EDForhandler") {
            const smsInfo: SMSInfoDTO = {
              flowType,
              companyId,
              appointmentId,
              recipient:
                company.contactPhone + "*" + employee.name + "*employeename",
              localizationCode: language,
            };

            if (flow === "CheckinAppointment") {
              this.sendSmsMessage(smsInfo);
            }
          }
        }

        if (layoutData.code === "Reisswolf" && company.responsibleSMSEnabled) {
          const smsInfo: SMSInfoDTO = {
            flowType,
            companyId,
            appointmentId,
            recipient: company.responsiblePhoneNumber,
            localizationCode: language,
          };

          this.sendSmsMessage(smsInfo);
        }

        if (
          layoutData.code === "Reisswolf" &&
          company.responsibleEmailEnabled
        ) {
          const emailInfoReisswolf: SendEmailDTO = {
            flowType: 1,
            companyId,
            appointmentId,
            localizationCode: language,
          };

          this.sendEmailMessage(emailInfoReisswolf);
        }

        if (layoutData.code === "Seftest" || layoutData.code === "Sef") {
          const smsInfoCustomer: SMSInfoDTO = {
            flowType,
            companyId,
            appointmentId,
            recipient: data.phoneNumber + "*GDPR",
            localizationCode: language,
          };

          this.sendSmsMessage(smsInfoCustomer);
        } else if (
          layoutData.code === "LouisPoulsen" ||
          layoutData.code === "Flensted" ||
          layoutData.code === "FlenstedWorkshop"
        ) {
          const smsInfoCustomer: SMSInfoDTO = {
            flowType,
            companyId,
            appointmentId,
            recipient: data.phoneNumber + "*TextCustomer",
            localizationCode: language,
          };

          this.sendSmsMessage(smsInfoCustomer);
        }
        break;
      }
      case "CheckinNoAppointment": {
        flowType = 1;
        const emailInfo: SendEmailDTO = {
          flowType,
          companyId,
          appointmentId,
          localizationCode: language,
        };

        if (
          ClientConfig.flow.informAllEnabled &&
          company.responsibleEmailEnabled
        ) {
        } //Bedre
        this.sendEmailMessage(emailInfo);

        if (company.responsibleSMSEnabled) {
          const smsInfo: SMSInfoDTO = {
            flowType,
            companyId,
            appointmentId,
            recipient: company.responsiblePhoneNumber,
            localizationCode: language,
          };

          var stringResponsibleNumber = String(company.responsiblePhoneNumber);
          var listResponsibleNumber = stringResponsibleNumber.split("*");

          if (listResponsibleNumber.length > 1) {
            listResponsibleNumber.forEach((number) => {
              var smsInfoMultiple: SMSInfoDTO = {
                flowType,
                companyId,
                appointmentId,
                recipient: number,
                localizationCode: language,
              };

              this.sendSmsMessage(smsInfoMultiple);
              //console.log(number)
            });
          } else {
            //console.log(company.responsiblePhoneNumber)
            this.sendSmsMessage(smsInfo);
          }
        }
        break;
      }
      case "Carrier": {
        flowType = 2;

        const emailInfo: SendEmailDTO = {
          flowType,
          companyId,
          appointmentId,
          localizationCode: language,
        };

        if (company.courierEmailEnabled) this.sendEmailMessage(emailInfo);

        if (company.courierSMSEnabled) {
          const smsInfo: SMSInfoDTO = {
            flowType,
            companyId,
            appointmentId,
            recipient: company.courierPhoneNumber,
            localizationCode: language,
          };

          var stringcourierPhoneNumber = String(company.courierPhoneNumber);
          var listcourierPhoneNumber = stringcourierPhoneNumber.split("*");

          if (listcourierPhoneNumber.length > 1) {
            listcourierPhoneNumber.forEach((number) => {
              var smsInfoMultiple: SMSInfoDTO = {
                flowType,
                companyId,
                appointmentId,
                recipient: number,
                localizationCode: language,
              };

              this.sendSmsMessage(smsInfoMultiple);
              //console.log(number)
            });
          } else {
            //console.log(company.responsiblePhoneNumber)
            this.sendSmsMessage(smsInfo);
          }
        }
        break;
      }
      case "CheckinNoInfo": {
        flowType = 0;

        const emailInfo: SendEmailDTO = {
          flowType,
          companyId,
          appointmentId,
          localizationCode: language,
        };

        const employee = await dispatchRequest(
          await getEmployeeRequest(employeeId)
        );

        if (employee.emailNotification) this.sendEmailMessage(emailInfo);

        if (employee.smsNotification) {
          const smsInfo: SMSInfoDTO = {
            flowType,
            companyId,
            appointmentId,
            recipient: employee.phoneNumber,
            localizationCode: language,
          };

          this.sendSmsMessage(smsInfo);
        }
        break;
      }
      case "GroupCheckin": {
        flowType = 3;

        const emailInfo: SendEmailDTO = {
          flowType,
          companyId,
          appointmentId,
          localizationCode: language,
        };

        const employee = await dispatchRequest(
          await getEmployeeRequest(employeeId)
        );

        if (employee.emailNotification) this.sendEmailMessage(emailInfo);

        if (employee.smsNotification) {
          const smsInfo: SMSInfoDTO = {
            flowType,
            companyId,
            appointmentId,
            recipient: employee.phoneNumber,
            localizationCode: language,
          };

          //console.log(smsInfo);

          if (
            flow === "GroupCheckin" ||
            (flow === "GroupCheckin" && !ClientConfig.flow.informAllEnabled)
          ) {
            this.sendSmsMessage(smsInfo);
          }
        }

        if (layoutData.code === "Seftest" || layoutData.code === "Sef") {
          const smsInfoCustomer: SMSInfoDTO = {
            flowType,
            companyId,
            appointmentId,
            recipient: data.phoneNumber + "*GDPR",
            localizationCode: language,
          };

          this.sendSmsMessage(smsInfoCustomer);
        }
        break;
      }
      case "HotWork": {
        flowType = 1;

        const employee = await dispatchRequest(
          await getEmployeeRequest(employeeId)
        );

        if (employee.smsNotification) {
          const smsInfo: SMSInfoDTO = {
            flowType,
            companyId,
            appointmentId,
            recipient: employee.phoneNumber,
            localizationCode: language,
          };
          this.sendSmsMessage(smsInfo);
          //console.log("HotWorkSMS" + smsInfo)
        }
        break;
      }
      case "NotHotWork": {
        //console.log("1")
        flowType = 4;

        const employee = await dispatchRequest(
          await getEmployeeRequest(employeeId)
        );
        //console.log("2")
        if (employee.smsNotification) {
          const smsInfo: SMSInfoDTO = {
            flowType,
            companyId,
            appointmentId,
            recipient: employee.phoneNumber,
            localizationCode: language,
          };
          //console.log("3")
          this.sendSmsMessage(smsInfo);
          //console.log("NotHotWorkSMS" + smsInfo)
        }
        break;
      }
    }
    return true;
  };

  private finalizeFlow = async (
    flow: FlowType,
    purpose: string,
    employeeId: number,
    customer: CustomerDTO
  ) => {
    //console.log("2");
    var appointmentId = 0;
    try {
      let companyId = await dispatchRequest(await getCompanyGuidFromId()); // TODO: Temporary untill we get validation
      //console.log("CompanyID" + companyId);
      appointmentId = await dispatchRequest(
        await finilizeFlowRequest(
          companyId,
          customer,
          employeeId,
          flow,
          purpose
        )
      );
      //console.log("AppointmentID" + appointmentId);
    } catch {
      console.log("Could not finalize flow.");
    }
    //console.log("3" + appointmentId);
    return appointmentId;
  };

  private sendEmailMessage = async (emailInfo: SendEmailDTO) => {
    try {
      await dispatchRequest(await sendEmail(emailInfo));
    } catch {
      console.log("Could not send email.");
    }
  };

  private sendSmsMessage = async (smsInfo: SMSInfoDTO) => {
    try {
      await dispatchRequest(await sendSMSRequest(smsInfo));
    } catch {
      console.log("Could not send SMS.");
    }
  };

  // 2024-09-18 TEST DATA FOR ZEBRA PRINTER
  //var zebraConnectionType = "Network";
  //var zebraUsbPrinterName = "ZD421"; // Only needed for USB
  //var zebraPrinterIp = "192.168.7.133"; //Only needed for network
  //var zebraPrinterPort: 9100; //Only needed for network
  //var ZPLTemplate = "^XA\n~TA000\n~JSN\n^LT0\n^MNN\n^MTT\n^PON\n^PMN\n^LH0,0\n^JMA\n^PR3,3\n~SD8\n^JUS\n^LRN\n^CI27\n^PA0,1,1,0\n^XZ\n^XA\n^MMC\n^PW800\n^LL450\n^LS0\n^FO180,350^BY3,3,99^BCN,,Y,N\n^FH\\^FD{Phone}^FS\n^FT180,100^A0N,58,58^FH\\^CI28^FD{Name}^FS^CI27\n^FT180,200^A0N,42,43^FH\\^CI28^FD{Company}^FS\n^PQ1,1,1,Y\n^XZ";
  //var zebraPrinterUrl = 'http://localhost:5432/print';
  // END TEST DATA FOR ZEBRA PRINTER

  private printReceipt = async (
    companyId: number,
    employeeFullName: string,
    fullName: string,
    phoneNumber: string,
    companyName: string = ""
  ) => {
    const { layoutData, configData } = this.props;
    try {
      var printerSettings: CheckInPrinterSettingsDTO =
        configData.checkInPrinterSettings;
      if (printerSettings) {
        ZebraPrintGuestCard(
          printerSettings,
          fullName,
          companyName,
          phoneNumber
        );
        return;
      }
      await dispatchRequest(
        await printReceiptRequest({
          employeeFullName,
          fullName,
          phoneNumber,
          companyId,
          companyName,
        })
      );

      if (
        layoutData.code === "SSIDiagnostica" ||
        layoutData.code === "ZigNaturTraining" ||
        layoutData.code === "SolidConsult" ||
        layoutData.code === "KimKnirkholtJensen" ||
        layoutData.code === "CoachSpirit" ||
        layoutData.code === "SmartPractice"
      ) {
        await dispatchRequest(
          await printReceiptRequest({
            employeeFullName,
            fullName,
            phoneNumber,
            companyId,
          })
        );
      }
    } catch {
      console.log("Could not print.");
    }
  };

  private printLeverandoerReceipt = async (
    companyId: number,
    employeeFullName: string,
    fullName: string,
    phoneNumber: string
  ) => {
    try {
      await dispatchRequest(
        await printLeverandoerReceiptRequest({
          employeeFullName,
          fullName,
          phoneNumber,
          companyId,
        })
      );
    } catch {
      console.log("Could not print.");
    }
  };

  private generateRandom(length: number) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  private finishFlow = async (): Promise<void> => {
    const {
      currentFlow,
      responsibleEmployeeId,
      responsibleEmployeeName,
      data,
      customerCount,
      customerPurpose,
      layoutData,
    } = this.props;
    let companyId = await dispatchRequest(await getCompanyGuidFromId()); // TODO: Temporary untill we get validation
    //console.log("MessageEMPID: " + responsibleEmployeeId);
    //console.log("MessageEMPNAME: " + responsibleEmployeeName)

    //var emp: CustomerInfoDTO;
    var temp: CustomerInfoDTO[] = [];
    var CustomerNoInfoTemp: CustomerDTO = {
      firstName: responsibleEmployeeName + "'s",
      secondName: " klient",
      phoneNumber: "no" + responsibleEmployeeId,
      companyId: companyId,
      customerInfos: temp,
    };
    var CustomerNoInfoTemp2: CustomerDTO = {
      firstName: responsibleEmployeeName + "'s",
      secondName: " mødedeltager",
      phoneNumber: "no" + responsibleEmployeeId,
      companyId: companyId,
      customerInfos: temp,
    };

    var customCustomer: CustomerDTO;
    if (
      (layoutData.code === "Sef" &&
        currentFlow !== ("GroupCheckin" as FlowType)) ||
      (layoutData.code === "Seftest" &&
        currentFlow !== ("GroupCheckin" as FlowType))
    ) {
      customCustomer = {
        firstName: data.firstName,
        secondName: data.secondName,
        phoneNumber: data.phoneNumber,
        companyId: data.companyId,
        customerInfos: temp,
      };
    } else if (layoutData.code === "EDForhandler") {
      try {
        customCustomer = {
          firstName: data.firstName,
          secondName: data.secondName,
          phoneNumber: data.customerInfos[0].attributeValue,
          companyId: data.companyId,
          customerInfos: data.customerInfos,
        };
      } catch {}
    } else {
      customCustomer = data;
    }

    var edCustomer: CustomerDTO = {
      firstName: data.firstName,
      secondName: data.secondName,
      phoneNumber: "GeneratedNumber: " + this.generateRandom(25),
      companyId: data.companyId,
      customerInfos: data.customerInfos,
    };

    var reisswolfCompany = "";

    if (layoutData.code === "Reisswolf") {
      reisswolfCompany = data.customerInfos[0].attributeValue;
    }

    var reisswolfCustomer: CustomerDTO = {
      firstName: data.firstName,
      secondName: data.secondName,
      phoneNumber:
        reisswolfCompany +
        "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0  GeneratedNumber: " +
        this.generateRandom(25),
      companyId: data.companyId,
      customerInfos: data.customerInfos,
    };

    var hinCustomer: CustomerDTO = {
      firstName: "En",
      secondName: "person med en aftale",
      phoneNumber: "GeneratedNumber: " + this.generateRandom(25),
      companyId: data.companyId,
      customerInfos: data.customerInfos,
    };

    var eMCustomerNoAppointment: CustomerDTO = {
      firstName: "En person ",
      secondName: "der har brug for hjælp",
      phoneNumber: "no",
      companyId: data.companyId,
      customerInfos: data.customerInfos,
    };

    var hinCustomerNoAppointment: CustomerDTO = {
      firstName: "En",
      secondName: "person uden aftale",
      phoneNumber: "No Appointment",
      companyId: data.companyId,
      customerInfos: data.customerInfos,
    };

    var edCustomerNoAppointment: CustomerDTO = {
      firstName: "En",
      secondName: "person uden aftale",
      phoneNumber: "No Appointment",
      companyId: data.companyId,
      customerInfos: temp,
    };

    var fundamentCustomer: CustomerDTO = {
      firstName: data.firstName,
      secondName: data.secondName,
      phoneNumber:
        responsibleEmployeeName +
        "⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀" +
        this.generateRandom(25),
      companyId: data.companyId,
      customerInfos: data.customerInfos,
    };

    var lauritzenBulkersCustomer: CustomerDTO = {
      firstName: data.firstName,
      secondName: data.secondName,
      phoneNumber:
        "⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀" +
        this.generateRandom(25),
      companyId: data.companyId,
      customerInfos: data.customerInfos,
    };

    var heimdallsCustomer: CustomerDTO = {
      firstName: data.firstName,
      secondName: data.secondName,
      phoneNumber: "GeneratedNumber: " + this.generateRandom(25),
      companyId: data.companyId,
      customerInfos: data.customerInfos,
    };

    var totalEnergiesCustomer: CustomerDTO = {
      firstName: data.firstName,
      secondName: data.secondName,
      phoneNumber: data.phoneNumber,
      companyId: data.companyId,
      customerInfos: data.customerInfos,
    };
    console.log(totalEnergiesCustomer);

    var menetaCustomer: CustomerDTO = {
      firstName: data.firstName,
      secondName: data.secondName,
      phoneNumber: data.phoneNumber,
      companyId: data.companyId,
      customerInfos: data.customerInfos,
    };
    var dsStaalCustomer: CustomerDTO = {
      firstName: data.firstName,
      secondName: data.secondName,
      phoneNumber: data.phoneNumber,
      companyId: data.companyId,
      customerInfos: data.customerInfos,
    };

    var appointmentId;
    switch (currentFlow) {
      case "CheckinAppointment": {
        if (
          layoutData.code === "EDForhandler" ||
          layoutData.code === "Kalundborg" ||
          layoutData.code === "Kjaergaard"
        ) {
          //console.log(data.customerInfos[0].attributeValue)
          await this.printReceipt(
            companyId,
            responsibleEmployeeName,
            data.firstName + " " + data.secondName,
            data.customerInfos[0].attributeValue
          );
        } else if (layoutData.code === "Comm2ig") {
          await this.printReceipt(
            companyId,
            responsibleEmployeeName,
            data.firstName + " " + data.secondName,
            " "
          );
        } else if (
          layoutData.code === "Flensted" ||
          layoutData.code === "FlenstedWorkshop"
        ) {
          await this.printReceipt(
            companyId,
            "",
            data.firstName + " " + data.secondName,
            data.customerInfos[0].attributeValue
          );
        } else {
          await this.printReceipt(
            companyId,
            responsibleEmployeeName,
            data.firstName + " " + data.secondName,
            data.phoneNumber,
            data.companyName
          );
        }
        //console.log(data);
        if (layoutData.code === "EDForhandler") {
          appointmentId = (await this.finalizeFlow(
            currentFlow,
            customerPurpose,
            responsibleEmployeeId,
            edCustomer
          )) as number;
          //console.log("DEN GÅR I 59")
        } else if (layoutData.code === "Reisswolf") {
          appointmentId = (await this.finalizeFlow(
            currentFlow,
            customerPurpose,
            responsibleEmployeeId,
            reisswolfCustomer
          )) as number;
        } else if (layoutData.code === "HIN") {
          appointmentId = (await this.finalizeFlow(
            currentFlow,
            customerPurpose,
            responsibleEmployeeId,
            hinCustomer
          )) as number;
        } else if (layoutData.code === "Fundamentet") {
          appointmentId = (await this.finalizeFlow(
            currentFlow,
            customerPurpose,
            responsibleEmployeeId,
            fundamentCustomer
          )) as number;
        } else if (
          layoutData.code === "Lauritzen" ||
          layoutData.code === "Bulkers"
        ) {
          appointmentId = (await this.finalizeFlow(
            currentFlow,
            customerPurpose,
            responsibleEmployeeId,
            lauritzenBulkersCustomer
          )) as number;
        } else if (layoutData.code === "Heimdalls") {
          if (currentFlow === "CheckinAppointment")
            heimdallsCustomer.customerInfos = []; //Hacky as f, but sometimes it does not reset properly
          appointmentId = (await this.finalizeFlow(
            currentFlow,
            customerPurpose,
            responsibleEmployeeId,
            heimdallsCustomer
          )) as number;
        } else if (layoutData.code === "TotalEnergies") {
          appointmentId = (await this.finalizeFlow(
            currentFlow,
            customerPurpose,
            responsibleEmployeeId,
            totalEnergiesCustomer
          )) as number;
        } else {
          appointmentId = (await this.finalizeFlow(
            currentFlow,
            customerPurpose,
            responsibleEmployeeId,
            data
          )) as number;
        }
        if (layoutData.code !== "Fundamentet") {
          this.sendNotifications(
            companyId,
            currentFlow,
            appointmentId,
            responsibleEmployeeId
          );
        }
        setCustomerData({});
        break;
      }
      case "CheckinNoAppointment": {
        if (
          layoutData.code === "Sef" ||
          layoutData.code === "Seftest" ||
          layoutData.code === "EDForhandler"
        ) {
        } else {
          //await this.printReceipt(responsibleEmployeeName, data.firstName + ' ' + data.secondName, data.phoneNumber);
        }

        //console.log(data);
        if (layoutData.code === "EDForhandler") {
          appointmentId = (await this.finalizeFlow(
            currentFlow,
            customerPurpose,
            responsibleEmployeeId,
            edCustomerNoAppointment
          )) as number;
          //console.log("DEN GÅR I 59")
        } else if (layoutData.code === "Sef" || layoutData.code === "Seftest") {
        } else if (layoutData.code === "ErhvershusMidt") {
          appointmentId = (await this.finalizeFlow(
            currentFlow,
            customerPurpose,
            responsibleEmployeeId,
            eMCustomerNoAppointment
          )) as number;
        } else if (layoutData.code === "HIN") {
          appointmentId = (await this.finalizeFlow(
            currentFlow,
            customerPurpose,
            responsibleEmployeeId,
            hinCustomerNoAppointment
          )) as number;
        } else if (layoutData.code === "Desmi") {
          await this.printReceipt(
            companyId,
            responsibleEmployeeName,
            data.firstName + " " + data.secondName,
            data.phoneNumber
          );
        } else {
          appointmentId = (await this.finalizeFlow(
            currentFlow,
            customerPurpose,
            responsibleEmployeeId,
            data
          )) as number;
        }
        if (layoutData.code === "Sef" || layoutData.code === "Seftest") {
        } else {
          this.sendNotifications(
            companyId,
            currentFlow,
            appointmentId,
            responsibleEmployeeId
          );
        }
        setCustomerData({});
        break;
      }
      case "Carrier": {
        //await this.printReceipt(companyId, responsibleEmployeeName, data.firstName + ' ' + data.secondName, data.phoneNumber);
        const appointmentId = (await this.finalizeFlow(
          currentFlow,
          customerPurpose,
          responsibleEmployeeId,
          data
        )) as number;
        this.sendNotifications(
          companyId,
          currentFlow,
          appointmentId,
          responsibleEmployeeId
        );
        break;
      }
      case "Checkout":
        //this.registerCheckout(data);
        await this.finalizeFlow(
          currentFlow,
          customerPurpose,
          responsibleEmployeeId,
          data
        );
        break;
      case "CheckinNoInfo": {
        await this.printReceipt(
          companyId,
          responsibleEmployeeName,
          "Mødedeltager",
          ""
        );
        //console.log(data);
        if (layoutData.code === "ErhvershusMidt") {
          appointmentId = (await this.finalizeFlow(
            "CheckinAppointment",
            customerPurpose,
            responsibleEmployeeId,
            CustomerNoInfoTemp2
          )) as number;
        } else {
          appointmentId = (await this.finalizeFlow(
            "CheckinAppointment",
            customerPurpose,
            responsibleEmployeeId,
            CustomerNoInfoTemp
          )) as number;
        }
        this.sendNotifications(
          companyId,
          currentFlow,
          appointmentId,
          responsibleEmployeeId
        );
        break;
      }
      case "GroupCheckin": {
        for (let i = 0; i < customerCount; i++) {
          await this.printReceipt(
            companyId,
            responsibleEmployeeName,
            data.firstName + " " + data.secondName,
            data.phoneNumber
          );
        }
        //console.log(data);

        appointmentId = (await this.finalizeFlow(
          "CheckinAppointment" as FlowType,
          customerPurpose,
          responsibleEmployeeId,
          customCustomer
        )) as number;
        this.sendNotifications(
          companyId,
          currentFlow,
          appointmentId,
          responsibleEmployeeId
        );
        break;
      }
      case "LeverandoerCheckin": {
        await this.printLeverandoerReceipt(
          companyId,
          "Leverandør",
          data.firstName + " " + data.secondName,
          data.customerInfos[0].attributeValue
        );

        var space =
          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0";

        var LeveradoerCustomer: CustomerDTO = {
          firstName: data.firstName,
          secondName: data.secondName,
          phoneNumber:
            "Leverandør: " +
            data.customerInfos[0].attributeValue +
            space +
            "**" +
            this.generateRandom(25),
          companyId: data.companyId,
          customerInfos: data.customerInfos,
        };

        await this.finalizeFlow(
          "LeverandoerCheckin" as FlowType,
          customerPurpose,
          responsibleEmployeeId,
          LeveradoerCustomer
        );
        break;
      }
      case "Event": {
        await this.printLeverandoerReceipt(
          companyId,
          "Event",
          data.firstName + " " + data.secondName,
          data.customerInfos[0].attributeValue
        );

        await this.finalizeFlow(
          "CheckinNoAppointment" as FlowType,
          customerPurpose,
          responsibleEmployeeId,
          edCustomer
        );
        break;
      }
      case "HotWork": {
        const flow = "Carpenter" as FlowType;
        appointmentId = (await this.finalizeFlow(
          flow,
          customerPurpose,
          responsibleEmployeeId,
          menetaCustomer
        )) as number;
        this.sendNotifications(
          companyId,
          currentFlow,
          appointmentId,
          responsibleEmployeeId
        );
        break;
      }
      case "NotHotWork": {
        //console.log("1")
        const flow = "Carpenter" as FlowType;
        appointmentId = (await this.finalizeFlow(
          flow,
          customerPurpose,
          responsibleEmployeeId,
          menetaCustomer
        )) as number;
        //console.log("Hold up" + appointmentId)
        this.sendNotifications(
          companyId,
          currentFlow,
          appointmentId,
          responsibleEmployeeId
        );
        break;
      }
      case "Carpenter": {
        if (layoutData.code === "DSStaal") {
          appointmentId = (await this.finalizeFlow(
            currentFlow,
            customerPurpose,
            responsibleEmployeeId,
            dsStaalCustomer
          )) as number;
          this.sendNotifications(
            companyId,
            currentFlow,
            appointmentId,
            responsibleEmployeeId
          );
        }
      }
    }
    this.setState({ loading: false });
  };

  private getGoodbyeMessage = (): string => {
    const {
      currentFlow,
      responsibleEmployeeName,
      language,
      layoutData,
      languageData,
    } = this.props;
    var currentLanguageOptions = languageData.find(
      ({ localizationCode }) => localizationCode === language
    );

    const replacements = {
      "\\[X medarbejder\\]": responsibleEmployeeName,
      "\\[X employee\\]": responsibleEmployeeName,
    };
    const stringToReplace = language === "DK" ? "en medarbejder" : "someone";

    var edit = "";

    const replaceMultiplePhrases = (
      text: string,
      replacements: { [key: string]: string }
    ): string => {
      for (let phrase in replacements) {
        const regex = new RegExp(phrase, "g");
        text = text.replace(regex, replacements[phrase]);
      }
      return text;
    };
    //console.log(languageData)
    //console.log(currentLanguageOptions)
    switch (currentFlow) {
      case "CheckinAppointment": {
        if (
          currentLanguageOptions &&
          currentLanguageOptions.appointmentText &&
          currentLanguageOptions.appointmentText !== ""
        )
          return replaceMultiplePhrases(
            currentLanguageOptions.appointmentText,
            replacements
          );
        else if (
          language === "DK" &&
          layoutData.code === "OestjyllandsBrandvæsen"
        ) {
          edit = this.strings().checkinMessage.replace(
            stringToReplace,
            responsibleEmployeeName
          );
          return edit.replace(" og tag plads", "");
        } else if (language === "EN" && layoutData.code === "SociatyOfLife") {
          return responsibleEmployeeName + " has been notified.";
        } else if (layoutData.code === "Kjaergaard") {
          return this.strings().kjaergaardThanksRegistered;
        } else if (language === "DK" && layoutData.code === "Kilde") {
          return (
            this.strings().appointmentMessage1part1 +
            responsibleEmployeeName +
            this.strings().appointmentMessage1part2
          );
        } else if (language === "EN" && layoutData.code === "Kilde") {
          return (
            this.strings().appointmentMessage1part1 +
            responsibleEmployeeName +
            this.strings().appointmentMessage1part2
          );
        } else
          return responsibleEmployeeName
            ? this.strings().checkinMessage.replace(
                stringToReplace,
                responsibleEmployeeName
              )
            : this.strings().checkinMessage;
      }
      case "CheckinNoAppointment": {
        if (
          language === "DK" &&
          currentLanguageOptions &&
          currentLanguageOptions.doesNotHaveAnAppointmentText &&
          currentLanguageOptions.doesNotHaveAnAppointmentText !== "" &&
          layoutData.code === "OestjyllandsBrandvæsen"
        ) {
          if (ClientConfig.flow.informAllEnabled) {
            edit = currentLanguageOptions.doesNotHaveAnAppointmentText;
            return edit.replace(" og tag plads", "");
          } else {
            edit = currentLanguageOptions.doesNotHaveAnAppointmentText.replace(
              stringToReplace,
              responsibleEmployeeName
            );
            return edit.replace(" og tag plads", "");
          }
        } else if (
          ClientConfig.flow.informAllEnabled &&
          layoutData.code === "OestjyllandsBrandvæsen"
        ) {
          edit = this.strings().checkinMessage;
          return edit.replace(" og tag plads", "");
        } else if (
          language === "DK" &&
          layoutData.code === "OestjyllandsBrandvæsen"
        ) {
          edit = this.strings().checkinMessage.replace(
            stringToReplace,
            responsibleEmployeeName
          );
          return edit.replace(" og tag plads", "");
        } else if (language === "DK" && layoutData.code === "Kilde") {
          return this.strings().noAppointmentMessage1;
        } else if (language === "EN" && layoutData.code === "Kilde") {
          return this.strings().noAppointmentMessage1;
        } else if (layoutData.code === "Kjaergaard") {
          return this.strings().kjaergaardNoAppointment;
        } else if (layoutData.code === "Haugen") {
          return this.strings().HaugenNoAppointment;
        } else if (
          currentLanguageOptions &&
          currentLanguageOptions.doesNotHaveAnAppointmentText &&
          currentLanguageOptions.doesNotHaveAnAppointmentText !== ""
        ) {
          if (ClientConfig.flow.informAllEnabled)
            return currentLanguageOptions.doesNotHaveAnAppointmentText;
          else if (layoutData.code === "BirchEjendomme") {
            return currentLanguageOptions.doesNotHaveAnAppointmentText;
          } else {
            return currentLanguageOptions.doesNotHaveAnAppointmentText.replace(
              stringToReplace,
              responsibleEmployeeName
            );
          }
        } else if (ClientConfig.flow.informAllEnabled) {
          return this.strings().checkinMessage;
        } else
          return this.strings().checkinMessage.replace(
            stringToReplace,
            responsibleEmployeeName
          );
      }
      case "Carrier": {
        if (
          currentLanguageOptions &&
          currentLanguageOptions.courierText &&
          currentLanguageOptions.courierText !== ""
        )
          return currentLanguageOptions.courierText;
        else if (
          language === "DK" &&
          layoutData.code === "OestjyllandsBrandvæsen"
        ) {
          edit = this.strings().checkinMessage;
          return edit.replace(" og tag plads", "");
        } else if (language === "EN" && layoutData.code === "SociatyOfLife") {
          return "We have sent e message to our warehouse.";
        } else if (language === "DK" && layoutData.code === "OnRobot") {
          return "Kør herom for aflevering pakker og breve.";
        } else if (language === "EN" && layoutData.code === "OnRobot") {
          return "Drive here for delivery of packages and letters.";
        } else if (layoutData.code === "Haugen") {
          return this.strings().haugenCarrier;
        } else return this.strings().checkinMessage;
      }
      case "Checkout": {
        if (
          currentLanguageOptions &&
          currentLanguageOptions.checkoutText &&
          currentLanguageOptions.checkoutText !== ""
        )
          return currentLanguageOptions.checkoutText;
        else if (layoutData.code === "Kjaergaard") {
          return this.strings().kjaergaardThanksForVisit;
        } else return this.strings().checkoutMessage;
      }
      case "HR": {
        if (language === "EN") return "If you do not have an appointment,";
        else if (
          language === "DK" &&
          currentLanguageOptions &&
          currentLanguageOptions.checkoutText &&
          currentLanguageOptions.checkoutText !== ""
        )
          return currentLanguageOptions.checkoutText;
        else return this.strings().checkoutMessage;
      }
      case "Other": {
        if (language === "EN") return "If you do not have an appointment,";
        else if (
          language === "DK" &&
          currentLanguageOptions &&
          currentLanguageOptions.checkoutText &&
          currentLanguageOptions.checkoutText !== ""
        )
          return currentLanguageOptions.checkoutText;
        else return this.strings().checkoutMessage;
      }
      case "CheckinNoInfo": {
        if (layoutData.code === "Retograad") {
          return responsibleEmployeeName + " kommer lige om lidt";
        } else {
          return (
            responsibleEmployeeName + this.strings().Erhvervmidtjyllandmessage
          );
        }
      }
      case "GroupCheckin": {
        if (
          currentLanguageOptions &&
          currentLanguageOptions.appointmentText &&
          currentLanguageOptions.appointmentText !== ""
        )
          return currentLanguageOptions.appointmentText;
        else
          return this.strings().checkinMessage.replace(
            stringToReplace,
            responsibleEmployeeName
          );
      }
      case "LeverandoerCheckin": {
        return this.strings().levenrandoerGoodbye;
      }
      case "HotWork": {
        return this.strings().checkinMessage;
      }
      case "NotHotWork": {
        return this.strings().checkinMessage;
      }
      case "Carpenter": {
        return this.strings().simpleCheckinCompleteMessage;
      }
    }
  };

  private styleMessage(top: number): React.CSSProperties {
    return {
      position: "relative",
      top: top + "px",
      textAlign: "center",
      paddingLeft: "50px",
      paddingRight: "50px",
      fontSize: 30,
      lineHeight: 3,
    };
  }

  private addMessage() {
    const { responsibleEmployeeName, language, currentFlow, layoutData } =
      this.props;

    const stringToReplaceEdForhandler =
      language === "DK" ? "Din vært" : "Your host";

    const stringToReplace = language === "DK" ? "din vært" : "your host";
    const resposibleEmployeeString = this.strings().goodbyeMessage1.replace(
      stringToReplace,
      responsibleEmployeeName
    );

    if (layoutData.code === "SociatyOfLife") {
      return (
        <div style={StylesUtil.getMessageStyle(layoutData, {})}>
          {this.getGoodbyeMessage()} {this.addFlowCardPhoneNumber()}{" "}
        </div>
      );
    } else if (layoutData.code === "Retograad") {
      return (
        <div
          style={{
            backgroundColor: ClientConfig.secondaryColor,
            position: "absolute",
            top: 465,
            width: "1860px",
            height: 585,
          }}
        >
          <div style={this.styleMessage(40)}>
            Vi har nu registreret, at du er kommet
          </div>
          <div
            style={{
              textDecoration: "underline",
              position: "relative",
              fontSize: 30,
              fontWeight: "bold",
              top: 80,
            }}
          >
            Tag plads i venteområdet
          </div>
          <div style={this.styleMessage(105)}>
            Du er velkommen til, at tage en kop kaffe, the eller vand <br />{" "}
            {this.getGoodbyeMessage()}{" "}
          </div>
          <div style={this.returnStyle()} onClick={this.returnClick}>
            Tilbage
          </div>
        </div>
      );
    } else if (
      layoutData.code === "OfficePartner" &&
      currentFlow === "CheckinAppointment"
    ) {
      return (
        <div>
          <h1
            style={{
              position: "absolute",
              top: "25%",
              left: "15%",
              right: "15%",
              justifyContent: "left",
              textAlign: "left",
            }}
          >
            {resposibleEmployeeString}{" "}
          </h1>
          <h1
            style={{
              position: "absolute",
              top: "40%",
              left: "15%",
              right: "15%",
              justifyContent: "left",
              textAlign: "left",
            }}
          >
            {this.strings().goodbyeMessage2}{" "}
          </h1>
          <h1
            style={{
              position: "absolute",
              top: "42.5%",
              left: "15%",
              right: "15%",
              justifyContent: "left",
              textAlign: "left",
            }}
          >
            {this.strings().goodbyeMessage3}{" "}
          </h1>
        </div>
      );
    } else if (layoutData.code === "EDForhandler") {
      //console.log("ED" + (this.getGoodbyeMessage().split("**")[0]))
      switch (currentFlow) {
        case "CheckinAppointment": {
          return (
            <div>
              <h2
                style={{
                  position: "absolute",
                  top: "35%",
                  left: "15%",
                  right: "15%",
                  color: ClientConfig.mainColor,
                  fontWeight: "bolder",
                }}
              >
                {this.getGoodbyeMessage()
                  .split("**")[0]
                  .replace(
                    stringToReplaceEdForhandler,
                    responsibleEmployeeName
                  )}{" "}
              </h2>
              <h2
                style={{
                  position: "absolute",
                  top: "60%",
                  left: "15%",
                  right: "15%",
                  color: ClientConfig.mainColor,
                  fontWeight: "bolder",
                }}
              >
                {this.getGoodbyeMessage()
                  .split("**")[1]
                  .replace(
                    stringToReplaceEdForhandler,
                    responsibleEmployeeName
                  )}{" "}
              </h2>
            </div>
          );
        }
        case "CheckinNoAppointment": {
          return (
            <div>
              <h2
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "15%",
                  right: "15%",
                  color: ClientConfig.mainColor,
                  fontWeight: "bolder",
                }}
              >
                {this.getGoodbyeMessage()}{" "}
              </h2>
            </div>
          );
        }
        case "Event": {
          return (
            <div>
              <h2
                style={{
                  position: "absolute",
                  top: "35%",
                  left: "15%",
                  right: "15%",
                  color: ClientConfig.mainColor,
                  fontWeight: "bolder",
                }}
              >
                {this.strings().EdEventMessage.split("**")[0]}{" "}
              </h2>
              <h2
                style={{
                  position: "absolute",
                  top: "60%",
                  left: "15%",
                  right: "15%",
                  color: ClientConfig.mainColor,
                  fontWeight: "bolder",
                }}
              >
                {this.strings().EdEventMessage.split("**")[1]}{" "}
              </h2>
            </div>
          );
        }
      }
    } else if (layoutData.code === "Fundamentet") {
      return (
        <div>
          <div style={StylesUtil.getMessageStyle(layoutData, {})}>
            Tak – Tag endelig en kop kaffe.
          </div>
          <div style={{ position: "absolute", left: 0, bottom: 0 }}>
            <HomeButton />
          </div>{" "}
        </div>
      );
    } else if (layoutData.code === "JKF") {
      switch (currentFlow) {
        case "Carrier":
          return (
            <div style={{ position: "absolute", top: 0 }}>
              <img
                src={window.location.origin + "/images/leveringskort.jpg"}
                style={{ height: 1080 }}
                alt="Leveringskort"
              />
            </div>
          );
        default:
          return (
            <div style={StylesUtil.getMessageStyle(layoutData, {})}>
              {this.getGoodbyeMessage()}
            </div>
          );
      }
    } else if (layoutData.code === "Kilde") {
      switch (currentFlow) {
        case "CheckinAppointment":
          return (
            <div style={{ position: "absolute", top: 200 }}>
              <div style={StylesUtil.getMessageStyle(layoutData, {})}>
                {this.strings().appointmentMessage1part1 +
                  " " +
                  responsibleEmployeeName +
                  this.strings().appointmentMessage1part2}
              </div>
              <img
                src={
                  window.location.origin +
                  "/images/large-Key-hanger-infoskærm.png"
                }
                style={{ height: 450, marginTop: 40, marginBottom: 40 }}
                alt="Keyhanger"
              />
              <div style={StylesUtil.getMessageStyle(layoutData, {})}>
                {this.strings().redForVisitors +
                  " " +
                  this.strings().blueForVisitors}
              </div>
            </div>
          );
        case "CheckinNoAppointment":
          return (
            <div style={{ position: "absolute", top: 200 }}>
              <div style={StylesUtil.getMessageStyle(layoutData, {})}>
                {this.strings().noAppointmentMessage1}
              </div>
              <img
                src={
                  window.location.origin +
                  "/images/large-Key-hanger-infoskærm.png"
                }
                style={{ height: 450, marginTop: 40, marginBottom: 40 }}
                alt="Keyhanger"
              />
              <div style={StylesUtil.getMessageStyle(layoutData, {})}>
                {this.strings().redForVisitors +
                  " " +
                  this.strings().blueForVisitors}
              </div>
            </div>
          );
        case "Carrier":
          return (
            <div style={{ position: "absolute", top: 40 }}>
              <img
                src={window.location.origin + "/images/KildeCourrierkort.jpg"}
                style={{ height: 915 }}
                alt="Leveringskort"
              />
            </div>
          );
        default:
          return (
            <div style={StylesUtil.getMessageStyle(layoutData, {})}>
              {this.getGoodbyeMessage()}
            </div>
          );
      }
    } else if (layoutData.code === "OnRobot") {
      switch (currentFlow) {
        case "Carrier":
          return (
            <div style={{ position: "absolute", top: 40 }}>
              <div style={StylesUtil.getMessageStyle(layoutData, {})}>
                {this.getGoodbyeMessage()}
              </div>
              <img
                src={window.location.origin + "/images/On-robot-courrier.png"}
                style={{ height: 915 }}
                alt="Leveringskort"
              />
            </div>
          );
        default:
          return (
            <div style={StylesUtil.getMessageStyle(layoutData, {})}>
              {this.getGoodbyeMessage()}
            </div>
          );
      }
    } else if (layoutData.code === "Seftest" || layoutData.code === "Sef") {
      switch (currentFlow) {
        case "CheckinAppointment": {
          return (
            <div style={StylesUtil.getMessageStyle(layoutData, {})}>
              {this.getGoodbyeMessage()}
              <div style={{ paddingTop: 100 }}>
                {this.strings().sefGDPR}
              </div>{" "}
              <div
                style={{ color: "blue", textDecoration: "underline" }}
                onClick={() => this.openWindow()}
              >
                https://sef.dk/persondatapolitik/
              </div>
            </div>
          );
        }
        case "GroupCheckin": {
          return (
            <div style={StylesUtil.getMessageStyle(layoutData, {})}>
              {this.getGoodbyeMessage()}{" "}
              <div style={{ paddingTop: 100 }}>{this.strings().sefGDPR}</div>
              <div
                style={{ color: "blue", textDecoration: "underline" }}
                onClick={() => this.openWindow()}
              >
                https://sef.dk/persondatapolitik/
              </div>
            </div>
          );
        }
        default: {
          return (
            <div style={StylesUtil.getMessageStyle(layoutData, {})}>
              {this.getGoodbyeMessage()}
            </div>
          );
        }
      }
    } else if (layoutData.code === "Reisswolf") {
      switch (currentFlow) {
        case "CheckinAppointment": {
          return (
            <div>
              <div
                style={StylesUtil.getMessageStyle(layoutData, {
                  position: "relative",
                  top: 20,
                  fontSize: 120,
                  fontWeight: 700,
                })}
              >
                {this.strings().welcome.toUpperCase()}
              </div>
              <div
                style={StylesUtil.getMessageStyle(layoutData, {
                  position: "relative",
                  top: 80,
                  fontSize: 55,
                  fontFamily: "Inter",
                })}
              >
                {this.getGoodbyeMessage()}
              </div>
            </div>
          );
        }
        case "Checkout": {
          return (
            <div
              style={StylesUtil.getMessageStyle(layoutData, {
                position: "absolute",
                top: 720,
                fontSize: 140,
                fontWeight: 700,
              })}
            >
              {this.getGoodbyeMessage()}
            </div>
          );
        }
        default: {
          return (
            <div style={StylesUtil.getMessageStyle(layoutData, {})}>
              {this.getGoodbyeMessage()}
            </div>
          );
        }
      }
    } else if (layoutData.code === "Kjaergaard") {
      switch (currentFlow) {
        case "CheckinAppointment": {
          return (
            <div>
              <div className="header-container">{this.getGoodbyeMessage()}</div>
              <div
                style={StylesUtil.getMessageStyle(layoutData, {
                  fontWeight: 400,
                })}
              >
                {this.strings().kjaergaardGoToReception}
              </div>
              <div
                style={StylesUtil.getMessageStyle(layoutData, { fontSize: 40 })}
              >
                {responsibleEmployeeName}
              </div>
              <div
                style={StylesUtil.getMessageStyle(layoutData, {
                  fontWeight: 400,
                })}
              >
                {this.strings().kjaergaardFetchYou}
              </div>
            </div>
          );
        }
        case "CheckinNoAppointment": {
          return (
            <div>
              <div className="header-container">{this.getGoodbyeMessage()}</div>
              <div
                style={StylesUtil.getMessageStyle(layoutData, {
                  fontWeight: 400,
                })}
              >
                {this.strings().kjaergaardGoToHelp}
              </div>
            </div>
          );
        }
        case "Checkout": {
          return (
            <div>
              <div className="header-container">{this.getGoodbyeMessage()}</div>
              <div
                style={StylesUtil.getMessageStyle(layoutData, {
                  fontWeight: 400,
                })}
              >
                {this.strings().kjaergaardCheckedOut}
              </div>
              {this.addLogo()}
            </div>
          );
        }
      }
    } else if (layoutData.code === "Dragsbaek") {
      return (
        <div style={StylesUtil.getMessageStyle(layoutData, {})}>
          {this.getGoodbyeMessage()}
        </div>
      );
    } else if (layoutData.code === "Flensted") {
      switch (currentFlow) {
        case "CheckinNoAppointment":
          return (
            <div>
              <div style={StylesUtil.getMessageStyle(layoutData, {})}>
                {this.getGoodbyeMessage()}
              </div>
              <br />
              <img
                src={window.location.origin + "/images/FlenstedQR.png"}
                style={{ height: 200 }}
                alt="FlenstedQR"
              />
            </div>
          );
        default:
          return (
            <div style={StylesUtil.getMessageStyle(layoutData, {})}>
              {this.getGoodbyeMessage()}
            </div>
          );
      }
    } else if (
      layoutData.code === "Bulkers" ||
      layoutData.code === "Lauritzen"
    ) {
      return (
        <div
          style={{
            ...StylesUtil.getMessageStyle(layoutData, {}),
            fontSize: "32px",
          }}
        >
          <h1 style={{ fontSize: "36px", fontWeight: "bold" }}>
            {this.strings().welcome} {this.props.data.firstName}
          </h1>
          {this.getGoodbyeMessage()}
        </div>
      );
    } else {
      //console.log("generel")
      return (
        <div style={StylesUtil.getMessageStyle(layoutData, {})}>
          {this.getGoodbyeMessage()}
        </div>
      );
    }
  }

  private openWindow() {
    //console.log("test");
    var frame = document.getElementById("frame") as HTMLIFrameElement;

    frame.style.left = "0px";
    frame.style.top = "0px";
    frame.style.height = "1080px";
    frame.style.width = "1920px";
    frame.style.position = "absolute";
    frame.style.display = "inline";

    setTimeout(() => {
      var frame = document.getElementById("frame");
      frame.style.display = "none";
    }, 60000);
  }

  private returnClick = () => {
    const { resetStoreState } = this.props;

    resetStoreState();
  };

  private returnStyle(): React.CSSProperties {
    return {
      fontSize: "28px",
      height: "55px",
      width: "160px",
      color: ClientConfig.secondaryTextColor,
      backgroundColor: ClientConfig.mainColor,
      position: "absolute",
      top: 410,
      left: 850,
      textAlign: "center",
      paddingTop: "5px",
      fontWeight: "bold",
    };
  }

  private loadConfig = async (companyId: number) => {
    let config: ClientConfigDTO;
    try {
      config = await dispatchRequest(await getConfigRequest(companyId));
    } catch (e) {}

    if (config?.logoId !== 0) {
      const logo = await this.getCompanyImage(config.logoId);
      this.logo = logo;
    }
  };

  private getCompanyImage = async (imageId: number) => {
    try {
      let image = (await dispatchRequest(
        await getCompanyImage(imageId)
      )) as ImageDTO;
      return image.file;
    } catch {
      return "";
    }
  };

  public componentDidMount = async () => {
    const { layoutData } = this.props;

    let companyId = await dispatchRequest(await getCompanyGuidFromId()); // TODO: Temporary untill we get validation

    if (layoutData.code === "OfficePartner") {
      await this.loadConfig(companyId);
    }

    const company = await dispatchRequest(await getCompanyRequest(companyId));
    this.setState({ company });

    this.finishFlow();
    this.resetFast();
  };

  addFlowCardPhoneNumber(): React.ReactNode {
    const { currentFlow, layoutData } = this.props;

    switch (currentFlow) {
      case "HR": {
        if (layoutData.code === "SociatyOfLife") {
          //console.log()

          return (
            <div style={{ fontWeight: "normal", color: "#707070" }}>
              please call: <div> </div>{" "}
              <div
                style={{
                  position: "absolute",
                  top: "600px",
                  left: "810px",
                  fontWeight: "Bolder",
                  color: "#707070",
                }}
              >
                +45 96 99 00 00
              </div>
            </div>
          );
        }
        break;
      }
      case "Other": {
        if (layoutData.code === "SociatyOfLife") {
          //console.log()

          return (
            <div style={{ fontWeight: "normal", color: "#707070" }}>
              please call: <div> </div>{" "}
              <div
                style={{
                  position: "absolute",
                  top: "600px",
                  left: "810px",
                  fontWeight: "Bolder",
                  color: "#707070",
                }}
              >
                +45 96 99 00 00
              </div>
            </div>
          );
        }
        break;
      }
      case "Carrier": {
        if (layoutData.code === "SociatyOfLife") {
          return (
            <div style={{ fontWeight: "normal", color: "#707070" }}>
              Please wait.<div> </div>{" "}
              <div
                style={{
                  position: "absolute",
                  top: "600px",
                  left: "710px",
                  fontSize: "26px",
                  fontWeight: "normal",
                  color: "#707070",
                }}
              >
                Call +45 22 39 29 67 if it takes too long
              </div>
            </div>
          );
        }
        break;
      }
      case "CheckinAppointment": {
        if (layoutData.code === "SociatyOfLife") {
          return (
            <div
              style={{
                fontWeight: "normal",
                color: "#707070",
                fontSize: "25px",
              }}
            >
              Please take a seat, we will be with you shortly.
            </div>
          );
        }
        break;
      }
    }
  }

  // TODO: Special logo case
  private addLogo() {
    const { logo, currentFlow, layoutData } = this.props;

    //console.log("vores logo: " + this.logo);
    if (layoutData.code === "Reisswolf") {
      switch (currentFlow) {
        case "Checkout":
          return (
            <img
              src={logo}
              style={{
                position: "absolute",
                width: 640,
                height: 640,
                top: 0,
                left: 640,
              }}
              alt="Logo"
            />
          );
        default:
          return <img className="logo" src={logo} alt="Logo" />;
      }
    } else if (layoutData.code === "Kjaergaard") {
      return (
        <img
          className={`logo-${ClientConfig.layoutVersion}`}
          src={logo}
          style={{
            marginTop: "40px",
            width: "240px",
            height: "66px",
            alignContent: "center",
          }}
          alt="Kjaergaard logo"
        />
      );
    } else {
      return (
        <div style={{ position: "absolute", top: "50%" }}>
          <img src={this.logo} alt="Logo" />{" "}
        </div>
      );
    }
  }

  private resetFast = () => {
    const {
      resetStoreState,
      setCustomerData,
      currentFlow,
      layoutData,
      layoutsRedirect,
    } = this.props;

    var timeToReset = 10000;

    if (
      ClientConfig.layoutVersion === "v3" ||
      layoutData.code === "Seftest" ||
      layoutData.code === "Sef"
    ) {
      timeToReset = 15000;
    } else if (layoutData.code === "JKF" && currentFlow === "Carrier") {
      timeToReset = 30000;
    } else if (
      layoutData.code === "Kilde" &&
      currentFlow === "CheckinAppointment"
    ) {
      timeToReset = 30000;
    } else if (
      layoutData.code === "Kilde" &&
      currentFlow === "CheckinNoAppointment"
    ) {
      timeToReset = 20000;
    } else if (layoutData.code === "Kilde" && currentFlow === "Carrier") {
      timeToReset = 20000;
    } else if (layoutData.code === "OnRobot" && currentFlow === "Carrier") {
      timeToReset = 30000;
    } else if (
      layoutData.code === "Kjaergaard" ||
      layoutData.code === "Flensted" ||
      layoutData.code === "Haugen"
    ) {
      timeToReset = 15000;
    } else if (
      layoutData.code === "Lauritzen" ||
      layoutData.code === "Bulkers"
    ) {
      timeToReset = 7000;
    }

    if (layoutsRedirect.config.has(layoutData.code)) {
      setTimeout(() => {
        window.location.assign(layoutsRedirect.config.get(layoutData.code).url);
      }, timeToReset);
    } else {
      setTimeout(() => {
        resetStoreState();
        setCustomerData({}); //Very hacky, should happen in resetStore, but somehow it does not...
      }, timeToReset);
    }
  };

  render() {
    const { currentPage, currentFlow, layoutData } = this.props;
    const { loading } = this.state;

    if (currentPage === "MessagePage") {
      return (
        <div className="message-container">
          {layoutData.code !== "Kjaergaard" && this.addLogo()}
          {loading ? (
            <Spinner
              styles={StylesUtil.getSpinnerStyles(layoutData)}
              size={SpinnerSize.large}
            />
          ) : layoutData.code === "Rovsing" && currentFlow === "Carrier" ? (
            <div className="rovsing_image">
              <img src={Courier_Rovsing} alt="Rovsing courier" />
            </div>
          ) : (
            this.addMessage()
          )}
        </div>
      );
    } else {
      return <div>Error</div>;
    }
  }
}

const mapStateToProps = (
  state: IApplicationState
): Partial<
  IFlowState &
    ICustomerDataState &
    ILocalizationState &
    ILayoutDataState &
    IConfigDataState
> => {
  const {
    currentPage,
    currentFlow,
    responsibleEmployeeId,
    responsibleEmployeeName,
  } = state.flow;
  const { language } = state.localization;
  const { data, customerCount, customerPurpose } = state.customerData;
  const { layoutData, layoutsRedirect } = state.layoutData;
  const { languageData } = state.configData;
  const { configData } = state.configData;

  return {
    currentPage,
    currentFlow,
    data,
    customerCount,
    customerPurpose,
    responsibleEmployeeId,
    responsibleEmployeeName,
    language,
    layoutData,
    layoutsRedirect,
    languageData,
    configData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return bindActionCreators({ resetStoreState, setCustomerData }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(MessagePage);
