export const getCompanyGuid = (): string | null => {
    return getUrlParamsLowerCase()['companyid'] ?? null;
}

export const getDeviceId = (): string | null => {
    return getUrlParamsLowerCase()['deviceid'] ?? null;
};

const getUrlParamsLowerCase = (): { [key: string]: string } => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const lowercaseParams: { [key: string]: string } = {};

    urlParams.forEach((value, key) => {
        lowercaseParams[key.toLowerCase()] = value.toLowerCase();
    });

    return lowercaseParams;
}
