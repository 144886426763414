import * as React from 'react';
import { setLocalizationLanguage } from '../../../store/Localization/actions';
import { ILocalizationState } from '../../../store/Localization/types';

import './index.css'
import { IApplicationState } from '../../../store';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import HomeButton from '../HomeButton/HomeButton';
import { ILayoutDataState } from '../../../store/LayoutData/types';
import StylesUtil from '../../../utils/StylesUtil';

interface IDispatchProps {
    setLocalizationLanguage: typeof setLocalizationLanguage;
}

type Props = IDispatchProps & ILocalizationState & ILayoutDataState;

class LanguagePicker extends React.Component<Props> {

    private imageClick = (language: LocalizationLanguage) => {
        const { setLocalizationLanguage } = this.props;

        setLocalizationLanguage(language);
    }

    render() {
        const { language, layoutsRedirect, layoutData } = this.props;

        if (layoutData.code === "Lauritzen" || layoutData.code === "LauritzenHouse" || layoutData.code === "Bulkers") {
            return (
                <div>
                </div>
            );
        }
        else if (layoutData.code === "Flensted" || layoutData.code === "FlenstedWorkshop") {
            return (
                <div style={StylesUtil.getLanguageContainerStyle(layoutData)}>
                    <div style={StylesUtil.getLanguageButtonStyle(layoutData)} onClick={() => this.imageClick('DE')}>
                        <div style={StylesUtil.getLanguageImageStyle(layoutData, language === 'DE')}>
                            <div style={{ width: '100%' }}>DE</div>
                        </div>
                    </div>

                    <div style={StylesUtil.getLanguageButtonStyle(layoutData)} onClick={() => this.imageClick('EN')}>
                        <div style={StylesUtil.getLanguageImageStyle(layoutData, language === 'EN')}>
                            <div style={{ width: '100%' }}>EN</div>
                        </div>
                    </div>

                    <div style={StylesUtil.getLanguageButtonStyle(layoutData)} onClick={() => this.imageClick('DK')}>
                        <div style={StylesUtil.getLanguageImageStyle(layoutData, language === 'DK')}>
                            <div style={{ width: '100%' }}>DK</div>
                        </div>
                    </div>
                </div>
            );
        } else if (layoutsRedirect.config.has(layoutData.code)) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>

                    {layoutsRedirect.config.has(layoutData.code) &&
                        <div style={StylesUtil.getHomeContainerStyle(layoutData)} className="home-button-container">
                            <HomeButton />
                        </div>}
                    <div style={StylesUtil.getLanguageContainerStyle(layoutData)}>
                        <div style={StylesUtil.getLanguageButtonStyle(layoutData)} onClick={() => this.imageClick('EN')}>
                            <img style={StylesUtil.getLanguageImageStyle(layoutData, language === 'EN')} src={window.location.origin + '/images/united-kingdom.png'} alt="" />
                        </div>

                        <div style={StylesUtil.getLanguageButtonStyle(layoutData)} onClick={() => this.imageClick('DK')}>
                            <img style={StylesUtil.getLanguageImageStyle(layoutData, language === 'DK')} src={window.location.origin + '/images/denmark.png'} alt="" />
                        </div>
                    </div>
                </div>
            );
        } else if (layoutData.code === "Daka" || layoutData.code === "EcoMotion") {
            return (
                <div style={StylesUtil.getLanguageContainerStyle(layoutData)}>
                    <div style={StylesUtil.getLanguageButtonStyle(layoutData)} onClick={() => this.imageClick('DE')}>
                        <img style={StylesUtil.getLanguageImageStyle(layoutData, language === 'DE')} src={window.location.origin + '/images/german-flag.png'} alt="" />
                    </div>

                    <div style={StylesUtil.getLanguageButtonStyle(layoutData)} onClick={() => this.imageClick('EN')}>
                        <img style={StylesUtil.getLanguageImageStyle(layoutData, language === 'EN')} src={window.location.origin + '/images/united-kingdom.png'} alt="" />
                    </div>

                    <div style={StylesUtil.getLanguageButtonStyle(layoutData)} onClick={() => this.imageClick('DK')}>
                        <img style={StylesUtil.getLanguageImageStyle(layoutData, language === 'DK')} src={window.location.origin + '/images/denmark.png'} alt="" />
                    </div>
                </div>
            );
        } else if (layoutData.code === "Haugen") {
            return (
                <div style={StylesUtil.getLanguageContainerStyle(layoutData)}>
                    <div style={StylesUtil.getLanguageButtonStyle(layoutData)} onClick={() => this.imageClick('EN')}>
                        <img style={StylesUtil.getLanguageImageStyle(layoutData, language === 'EN')} src={window.location.origin + '/images/ENGsquare.png'} alt="" />
                    </div>

                    <div style={StylesUtil.getLanguageButtonStyle(layoutData)} onClick={() => this.imageClick('DK')}>
                        <img style={StylesUtil.getLanguageImageStyle(layoutData, language === 'DK')} src={window.location.origin + '/images/DKsquare.png'} alt="" />
                    </div>
                </div>
            );
        } else if (layoutData.code === "DSStaal") {
            return (
                <div style={StylesUtil.getLanguageContainerStyle(layoutData)}>
                    <div style={StylesUtil.getLanguageButtonStyle(layoutData)} onClick={() => this.imageClick('EN')}>
                        <img style={StylesUtil.getLanguageImageStyle(layoutData, language === 'EN')} src={window.location.origin + '/images/DSStaal/Flag_EN.png'} alt="" />
                    </div>

                    <div style={StylesUtil.getLanguageButtonStyle(layoutData)} onClick={() => this.imageClick('DK')}>
                        <img style={StylesUtil.getLanguageImageStyle(layoutData, language === 'DK')} src={window.location.origin + '/images/DSStaal/Flag_DK.png'} alt="" />
                    </div>
                </div>
            );
        } else {
            return (
                <div style={StylesUtil.getLanguageContainerStyle(layoutData)}>
                    <div style={StylesUtil.getLanguageButtonStyle(layoutData)} onClick={() => this.imageClick('EN')}>
                        <img style={StylesUtil.getLanguageImageStyle(layoutData, language === 'EN')} src={window.location.origin + '/images/united-kingdom.png'} alt="" />
                    </div>

                    <div style={StylesUtil.getLanguageButtonStyle(layoutData)} onClick={() => this.imageClick('DK')}>
                        <img style={StylesUtil.getLanguageImageStyle(layoutData, language === 'DK')} src={window.location.origin + '/images/denmark.png'} alt="" />
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = (state: IApplicationState): Partial<ILocalizationState & ILayoutDataState> => {
    const { language } = state.localization;
    const { layoutData, layoutsRedirect } = state.layoutData;

    return {
        language, layoutData, layoutsRedirect
    };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
    return bindActionCreators(
        { setLocalizationLanguage },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LanguagePicker);